import styled from "@emotion/styled";
import { ActivityComponentType } from "@stackflow/react";

import { AppScreen } from "../../stackflow/components";
import {
  Button,
  Container,
  Icon,
  IconContainer,
  Title,
  Text,
  Image,
  ButtonWrapper,
} from "./index.css";

import LogoMainImage from "../../assets/logo512.png";
import { Spacing } from "@components/common/Spacing";
import { useFlow } from "../../stackflow";

const HomePage: ActivityComponentType = () => {
  const { push } = useFlow();

  const handleMoveToNewMeet = () => {
    push("NewMeetName", {});
  };

  return (
    <AppScreen
      noAppBar
      accessoryBar={
        <ButtonWrapper>
          <Button onClick={handleMoveToNewMeet}>투표 만들기</Button>
        </ButtonWrapper>
      }
    >
      <Container>
        <Image src={LogoMainImage} />
        <Spacing height={50} />
        <Text>팀플, 모임, 스터디 약속 정할 땐!</Text>
        <Spacing height={7} />
        <Title>만날 시간</Title>
      </Container>
    </AppScreen>
  );
};

export default HomePage;
