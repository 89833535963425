export const ROUTE_PATHS = {
  HOME: "/home",
  DETAIL: "/detail/:meetId",
  NEW: {
    MEET_NAME: "/new/meet-name",
    MEET_DATE_SELECT: "/new/meet-date",
    MEET_TIME_SELECT: "/new/meet-time",
  },
  CREATE_RESULT: "/create-result/:meetId",
  // VOTE_SET_INFO_LOGIN: "/vote-login/:meetId",
  VOTE_PAGE: "/vote/:meetId",
  ResultPage: "/result/:meetId",
};

export const buildRoutePath = {
  voteSetInfoLogin: (meetId: string) => `/vote-set-info-login/${meetId}`,
  createResult: (meetId: string) => `/create-result/${meetId}`,
  detail: (meetId: string) => `/detail/${meetId}`,
  BottomSheet: {},
};
