import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-family: Arial, sans-serif;
`;

export const Title = styled.div`
  color: #333;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 20px;
  line-height: 35px;
`;

export const SubTitle = styled.div`
  color: #333;
  font-weight: 700;
  font-size: 20px;
  line-height: 35px;
`;

export const InfoText = styled.div`
  color: #8e8e8e;

  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;

export const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  position: relative;
  justify-content: center;
  text-align: center;
  cursor: pointer;
`;

export const Link = styled.div`
  color: #4ccb84;
  text-decoration: none;
  font-size: 16px;
  line-height: 20px;
  max-width: 300px;
  word-break: break-all;
`;

export const CopyButton = styled.div`
  background-color: #727272;
  color: #ffffff;
  border-radius: 10px;
  padding: 7px 16px;
  font-size: 12px;
  position: absolute;
  bottom: -45px;
  width: max-content;
  transform: translateX(50%);
  right: 50%;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-bottom-color: #727272;
    border-top: 0;
    margin-left: -5px;
    margin-top: -5px;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  padding: 0 18px 10px 18px;
  box-sizing: border-box;
`;

export const Button = styled.button<{ enable: boolean }>`
  width: 100%;
  background-color: ${(props) => (props.enable ? "#4CCB84" : "#bbb")};
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 15px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  cursor: pointer;
`;

export const Image = styled.img`
  width: 100px;
  height: 100px;
`;
