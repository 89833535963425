import {
  addMonths,
  differenceInCalendarDays,
  endOfMonth,
  subDays,
} from "date-fns";
import ko from "date-fns/locale/ko";
import { enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarContainer, Container } from "./index.css";

type Props = {
  startDateProps: [
    Date | undefined,
    React.Dispatch<React.SetStateAction<Date | undefined>>
  ];
  endDateProps: [
    Date | undefined,
    React.Dispatch<React.SetStateAction<Date | undefined>>
  ];
};

const DateRangePicker = ({
  startDateProps: [startDate, setStartDate],
  endDateProps: [endDate, setEndDate],
}: Props) => {
  return (
    <Container>
      <CalendarContainer>
        <DatePicker
          selected={startDate}
          onChange={(date) => {
            const startDate = date[0] ? date[0] : undefined;
            const endDate = date[1] ? date[1] : undefined;
            if (startDate && endDate) {
              const diffDate = differenceInCalendarDays(endDate, startDate);
              if (diffDate >= 14) {
                enqueueSnackbar("최대 14일까지 선택할 수 있어요", {
                  variant: "default",
                  autoHideDuration: 3000,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                  },
                  preventDuplicate: true,
                });
                return;
              }
            }
            setStartDate(date[0] ? date[0] : undefined);
            setEndDate(date[1] ? date[1] : undefined);
          }}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          inline
          locale={ko}
          minDate={new Date()}
          maxDate={endOfMonth(addMonths(new Date(), 2))}
        />
      </CalendarContainer>
    </Container>
  );
};

export default DateRangePicker;
