import React from "react";
import { Chip, Container } from "./index.css";
import addDays from "date-fns/addDays";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import isWeekend from "date-fns/isWeekend";
import nextMonday from "date-fns/nextMonday";
import { add, nextSaturday } from "date-fns";

type Props = {
  startDateProps: [
    Date | undefined,
    React.Dispatch<React.SetStateAction<Date | undefined>>
  ];
  endDateProps: [
    Date | undefined,
    React.Dispatch<React.SetStateAction<Date | undefined>>
  ];
};

const DateRangeOptions = ({
  startDateProps: [startDate, setStartDate],
  endDateProps: [endDate, setEndDate],
}: Props) => {
  const handleChipClick = (rangeType: string) => {
    const today = new Date();
    const nextMon = nextMonday(today);
    const nextSat = startOfWeek(addDays(today, 7), { weekStartsOn: 6 });

    switch (rangeType) {
      case "오늘부터 7일":
        setStartDate(today);
        setEndDate(addDays(today, 6));
        break;
      case "오늘부터 14일":
        setStartDate(today);
        setEndDate(addDays(today, 13));
        break;
      case "다음주 월부터 7일":
        setStartDate(nextMon);
        setEndDate(addDays(nextMon, 6));
        break;
      case "돌아오는 평일":
        if (isWeekend(today)) {
          setStartDate(startOfWeek(addDays(today, 1), { weekStartsOn: 1 }));
          setEndDate(endOfWeek(addDays(today, 1), { weekStartsOn: 1 }));
        } else {
          setStartDate(nextMon);
          setEndDate(addDays(nextMon, 4));
        }
        break;
      case "돌아오는 주말":
        setStartDate(nextSat);
        setEndDate(addDays(nextSat, 1));
        break;
      default:
        break;
    }
  };

  return (
    <Container>
      <Chip onClick={() => handleChipClick("오늘부터 7일")}>오늘부터 7일</Chip>
      <Chip onClick={() => handleChipClick("오늘부터 14일")}>
        오늘부터 14일
      </Chip>
      <Chip onClick={() => handleChipClick("다음주 월부터 7일")}>
        다음주 월부터 7일
      </Chip>
      <Chip onClick={() => handleChipClick("돌아오는 평일")}>
        돌아오는 평일
      </Chip>
      <Chip onClick={() => handleChipClick("돌아오는 주말")}>
        돌아오는 주말
      </Chip>
    </Container>
  );
};

export default DateRangeOptions;
