/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 1;
  padding: 0 2rem;
  box-sizing: border-box;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  background-color: #4ccb84;
  border-radius: 50%;
  margin-bottom: 20px;
`;

export const Icon = styled.div`
  font-size: 60px;
`;

export const Text = styled.div`
  text-align: center;
  color: #333;
  font-weight: 500;
  font-size: large;
  line-height: 24px;
`;

export const Title = styled.div`
  font-weight: 900;
  line-height: 35px;
  font-size: 20px;
  color: #4ccb84;
  margin-bottom: 20px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  padding: 0 18px 10px 18px;
  box-sizing: border-box;
`;

export const Button = styled.button`
  flex: 1;
  width: 100%;
  background-color: #4ccb84;
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 15px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  cursor: pointer;
`;
export const Image = styled.img`
  width: 150px;
  height: 150px;
`;
