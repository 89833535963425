import { useActions } from "@stackflow/react";
import { useStepActions } from "@stackflow/react";
import { stackflow } from "@stackflow/react";
import { historySyncPlugin } from "@stackflow/plugin-history-sync";
import { basicRendererPlugin } from "@stackflow/plugin-renderer-basic";

import { basicUIPlugin } from "@stackflow/plugin-basic-ui";

import "@stackflow/plugin-basic-ui/index.css";
import { ROUTE_PATHS } from "./routes";
import HomePage from "../components/Home";
import MeetName from "@components/new/pages/MeetName";
import MeetDateSelect from "@components/new/pages/MeetDateSelect";
import MeetTimeSelect from "@components/new/pages/MeetTimeSelect";
import CreateResultPage from "@components/CreateResult";
import VoteSetInfoLogin from "@components/VoteSetInfoLoginBottomSheet";
import VotePage from "@components/VotePage";
import ResultPage from "@components/ResultPage";

const initStackflow = () => {
  return stackflow({
    transitionDuration: 350,
    activities: {
      HomePage,
      NewMeetName: MeetName,
      NewMeetDateSelect: MeetDateSelect,
      NewMeetTimeSelect: MeetTimeSelect,
      CreateResultPage,
      // "BottomSheet/VoteSetInfoLogin": VoteSetInfoLogin,
      VotePage,
      ResultPage,
    },
    plugins: [
      historySyncPlugin({
        routes: {
          HomePage: ROUTE_PATHS.HOME,
          NewMeetName: ROUTE_PATHS.NEW.MEET_NAME,
          NewMeetDateSelect: ROUTE_PATHS.NEW.MEET_DATE_SELECT,
          NewMeetTimeSelect: ROUTE_PATHS.NEW.MEET_TIME_SELECT,
          CreateResultPage: ROUTE_PATHS.CREATE_RESULT,
          // "BottomSheet/VoteSetInfoLogin": ROUTE_PATHS.VOTE_SET_INFO_LOGIN,
          VotePage: ROUTE_PATHS.VOTE_PAGE,
          ResultPage: ROUTE_PATHS.ResultPage,
        },
        fallbackActivity: () => {
          return "HomePage";
        },
      }),
      basicRendererPlugin(),
      basicUIPlugin({ theme: "cupertino" }),
    ],
  });
};

const {
  Stack,
  activities,
  useFlow: useOriginFlow,
  useStepFlow: useOriginStepFlow,
} = initStackflow();

export type TypeActivities = typeof activities;
export type TypeActivityNames = keyof TypeActivities;
export type TypeUseFlow = typeof useOriginFlow;
export type TypeUseStepFlow = typeof useOriginStepFlow;

const useFlow: TypeUseFlow = () => useActions<TypeActivities>();
const useStepFlow: TypeUseStepFlow = (activityName: TypeActivityNames) =>
  useStepActions(activityName as never);

export { Stack, activities, useFlow, useStepFlow };
