/** @jsxImportSource @emotion/react */

import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

const TimePickerOuterWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: -5px;
  width: 100%;

  height: 1px;
  overflow: visible;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: flex-start;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const TimePicker = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 200px;
  max-height: 50vh;
  position: relative;

  overflow: visible;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0 0 5px 5px;
  //border top
  border-top: none;

  animation: ${fadeIn} 0.3s ease-in-out;
`;

const TimePickerInner = styled.div`
  overflow: scroll;
  width: 100%;
  height: 100%;
`;

const TimePickerItem = styled.div<{ disabled: boolean }>`
  font-size: 1rem;
  line-height: 20px;
  font-weight: 700;

  padding: 10px;
  color: ${(props) => (props.disabled ? "#ccc" : "#000")};
`;

export { TimePickerOuterWrapper, TimePicker, TimePickerItem, TimePickerInner };
