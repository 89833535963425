import React from "react";
import Spinner, { SpinnerProps } from ".";

function OverlaySpinnerView(props: SpinnerProps) {
  return (
    <div
      style={{
        position: "fixed",
        backgroundColor: "rgba(255, 255, 255, 0.3)",
        width: "100%",
        maxWidth: "100%",
        transform: "none",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 100000,
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",

          marginLeft: "0",
          marginRight: "0",
        }}
      >
        <Spinner {...props} />
      </div>
    </div>
  );
}

export default OverlaySpinnerView;
