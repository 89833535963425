import { useFlow } from "@/stackflow";
import { useActivity } from "@stackflow/react";
import { useEffect, useState } from "react";

export const NICKNAME_LOCALSTORAGE_KEY = "meettime/nickname";

type Props = {
  handleOpenLoginBottomSheet: () => void;
  setDefaultSelectedSlots: (nickname: string | undefined) => void;
};

function useLogin({
  handleOpenLoginBottomSheet,
  setDefaultSelectedSlots,
}: Props) {
  const { isTop } = useActivity();
  const [nickname, setNickname] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!isTop) return;
    const nikcNameLocalData = localStorage.getItem(NICKNAME_LOCALSTORAGE_KEY);
    if (nikcNameLocalData) {
      setDefaultSelectedSlots(nikcNameLocalData);
      setNickname(nikcNameLocalData);
      return;
    }
    handleOpenLoginBottomSheet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    nickname,
    setNickname,
    getNickname: () => {
      const nickname = localStorage.getItem(NICKNAME_LOCALSTORAGE_KEY);
      if (nickname === undefined) {
        handleOpenLoginBottomSheet();
        return undefined;
      }
      return nickname;
    },
  };
}

export default useLogin;
