/** @jsxImportSource @emotion/react */

import styled from "@emotion/styled";

// Styled components
const Container = styled.div<{ dayNum: number; enableOverflow: boolean }>`
  width: calc(100% - 20px);
  height: 100%;
  max-height: calc(100vh - 230px);
  overflow: ${(props) => (props.enableOverflow ? "auto" : "hidden")};
  margin: 0 10px;
  box-sizing: border-box;
  padding-bottom: 50px;
  padding-right: 30px;

  //scrollbar none
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

// 요일 박스 item wrapper
const DayLabel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: white;
  width: 50px;
  flex-shrink: 0;
  gap: 9px;
  z-index: 500;
  padding: 10px 5px;

  position: sticky;
  top: 0;
  left: 0;

  ::after {
    content: "";
    width: calc(100%);
    height: 30px;
    /* background-color: red; */
    opacity: 1;
    position: absolute;
    bottom: -30px;
    left: 0px;
    z-index: 3000;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 39%
    );

    pointer-events: none;
  }
`;

//첫번째 빈 박스
const EmptyDayLabel = styled(DayLabel)`
  height: max-content;

  position: sticky;

  top: 0;
  left: 0;

  z-index: 3000;

  ::after {
    content: "";
    width: 20px;
    height: 100%;
    /* background-color: red; */

    position: absolute;
    top: 0;
    right: -20px;

    z-index: 3000;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 39%
    );
    /* background: linear-gradient(90deg, rgba(120,120,120,1) 0%, rgba(120,120,120,0) 39%); */
    pointer-events: none;
    /* background: red; */
  }
`;

// 요일 표시
const DateKoTextLabel = styled.div`
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  color: #6dda9d;
`;

// 요일 박스 내 날짜 텍스트
const DateLabel = styled.div`
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  color: #303030;
`;

// row 맨 위 시간 text
const TimeTopTextLabel = styled.div`
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  color: #6dda9d;
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
`;

// row 맨 앞 시간 text
const TimeLabel = styled.div`
  width: 50px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  text-align: center;

  position: sticky;
  left: 0;
  z-index: 1000;
  background-color: white;

  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
`;

export const FirstTimeLabel = styled(TimeLabel)`
  ::after {
    content: "";
    width: 20px;
    height: 100%;
    /* background-color: red; */

    position: absolute;
    top: 0;
    right: -20px;

    z-index: 3000;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 39%
    );
    /* background: linear-gradient(90deg, rgba(120,120,120,1) 0%, rgba(120,120,120,0) 39%); */
    pointer-events: none;
    /* background: red; */
  }
`;

// 각각 시간 slot
const TimeSlot = styled(TimeLabel)<{ selected: boolean; hoverMode?: boolean }>`
  background-color: ${(props) => (props.selected ? "#66bb6a" : "#f0f0f0")};
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  position: relative;
  z-index: 1;
`;

const NoonWrapper = styled.div`
  gap: 5px;
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  position: relative;
  padding: 10px 0;
`;

const NoonText = styled.div`
  width: 50px;
  min-width: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  position: sticky;
  left: 0;
  z-index: 1000;
  background-color: white;
  font-size: 12px;
  color: #6dda9d;
`;
const NoonDivider = styled.div`
  width: 100%;
  border: 1px dashed #6dda9d;
`;

const AllColWrapper = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  gap: 5px;
`;

const ColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 5px;
`;

const HourColWrapper = styled(ColWrapper)`
  position: sticky;
  left: 0;
  top: 0;
  z-index: 1000;
`;

// 새로운 달
const NewMonthWrapper = styled.div`
  gap: 5px;
  width: 30px;
  height: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  position: relative;
  padding: 10px 0;
`;

const NewMonthDayLabel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: white;
  width: 21px;
  flex-shrink: 0;
  gap: 9px;
  z-index: 500;
  padding: 10px 0px;

  position: sticky;
  top: 0;
  left: 0;

  ::after {
    content: "";
    width: calc(100%);
    height: 30px;
    /* background-color: red; */
    opacity: 1;
    position: absolute;
    bottom: -30px;
    left: 0px;
    z-index: 3000;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 39%
    );

    pointer-events: none;
  }
`;

const NewMonthText = styled.div`
  /* width: 50px; */
  /* min-width: 50px; */

  bottom: 0;
  left: 0;
  right: 0;
  top: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  position: absolute;
  left: 0;
  z-index: 1000;
  background-color: white;
  font-size: 15px;
  color: #6dda9d;
  font-weight: 600;
  color: #303030;
`;
const NewMonthDivider = styled.div`
  margin: 0px 10px;
  height: 40px;
  width: 1px;
  border: 1px dashed #6dda9d;
`;

export {
  EmptyDayLabel,
  Container,
  DayLabel,
  DateLabel,
  TimeLabel,
  TimeSlot,
  DateKoTextLabel,
  NoonWrapper,
  NoonText,
  NoonDivider,
  AllColWrapper,
  ColWrapper,
  HourColWrapper,
  TimeTopTextLabel,
  NewMonthDivider,
  NewMonthText,
  NewMonthDayLabel,
  NewMonthWrapper,
};
