import { useQuery } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";
import { MEETTIME_API_PATH } from "../constants";
import { fetchInstance } from "../instance";

export type AvailableDateType = {
  _id?: string;
  date: string;
  hour: number;
};

export type MemberType = {
  _id: string;
  name: string;
  availableTime: AvailableDateType[];
};

export type MeetDetailResponse = {
  meet: {
    _id: string;
    title: string;
    openDate: {
      startDate: string;
      endDate: string;
      startTime: number;
      endTime: number;
    };
    members: MemberType[];
  };
};

export const getMeetDetailPath = (meetId: string) =>
  `${MEETTIME_API_PATH}/${meetId}`;

export const getMeetDetail = async (meetId: string) => {
  const { data } = await fetchInstance().get<
    undefined,
    AxiosResponse<MeetDetailResponse>
  >(getMeetDetailPath(meetId));

  return data;
};

export const useGetMeetDetail = (meetId: string) => {
  const { data, refetch, isLoading } = useQuery({
    queryKey: [getMeetDetailPath(meetId)],
    queryFn: async () => {
      if (!meetId) return;
      const result = await axios.get(getMeetDetailPath(meetId));
      return result.data;
    },
  });

  const response = data;

  return { meet: response, refetch, isLoading };
};

export default useGetMeetDetail;
