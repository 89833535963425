import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 1;
  box-sizing: border-box;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  padding: 0 18px 10px 18px;
  box-sizing: border-box;
`;

export const Button = styled.button<{ enable: boolean }>`
  width: 100%;
  background-color: ${(props) => (props.enable ? "#4CCB84" : "#bbb")};
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 15px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  cursor: pointer;
`;

export const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px 20px 20px 20px;
`;

export const TitleLayout = styled.div`
  width: 100%;
  display: flex;

  justify-content: space-between;
  align-items: flex-start;
`;
export const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  line-height: 27px;
`;

export const PreviewResult = styled.div`
  color: #949494;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* cursor: pointer; */

  width: 100%;
  display: flex;
  justify-content: flex-end;
  /* text-align: right; */
  padding: 10px 0;
`;

export const PreviewResultText = styled.div`
  width: "auto";
`;
export const Description = styled.div`
  color: #949494;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;
