import { ReactElement, useEffect } from "react";
import { SnackbarProvider } from "notistack";

import { Stack } from "./stackflow";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      staleTime: 0,
    },
  },
});

function App(): ReactElement {
  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider>
        <Stack />
      </SnackbarProvider>
    </QueryClientProvider>
  );
}

export default App;
