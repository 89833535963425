import React from "react";
import {
  Button,
  Container,
  CopyButton,
  Image,
  InfoText,
  Link,
  LinkContainer,
  SubTitle,
  Title,
  ButtonWrapper,
} from "./index.css";
import LogoMainImage from "../../assets/logo512.png";

import { AppScreen } from "../../stackflow/components";
import { Spacing } from "@components/common/Spacing";
import { copyToClipboard } from "@utils/link";
import { useQueryParams } from "../../stackflow/hooks/useQueryParams";
import { enqueueSnackbar } from "notistack";
import { useFlow } from "@/stackflow";
import { WEB_PATH } from "@/apis/constants";

const CreateResultPage: React.FC = () => {
  const { title, meetId } = useQueryParams();
  const { push } = useFlow();
  const link = `${WEB_PATH}/vote/${meetId}`;

  const handleMoveToVoteLogin = () => {
    push("VotePage", {
      meetId: meetId,
    });
  };

  return (
    <AppScreen
      accessoryBar={
        <ButtonWrapper>
          <Button enable onClick={handleMoveToVoteLogin}>
            투표하기
          </Button>
        </ButtonWrapper>
      }
    >
      <Container>
        <Image src={LogoMainImage} />
        <Spacing height={51} />
        <Title>{title}</Title>
        <SubTitle>투표를 만들었어요.</SubTitle>
        <Spacing height={48} />
        <InfoText>
          만든 투표를 검색할 수 없어요.
          <br />
          링크를 꼭 저장해주세요.
        </InfoText>
        <Spacing height={21} />
        <LinkContainer
          onClick={() => {
            copyToClipboard(link);
            enqueueSnackbar("링크가 복사되었어요!", {
              variant: "default",
              autoHideDuration: 800,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              preventDuplicate: true,
            });
          }}
        >
          <Link>{link}</Link>
          <CopyButton>탭해서 링크 복사</CopyButton>
        </LinkContainer>
      </Container>
    </AppScreen>
  );
};

export default CreateResultPage;
