import { useEffect, useRef } from "react";
import {
  TimePickerOuterWrapper,
  TimePicker,
  TimePickerItem,
  TimePickerInner,
} from "./TimeOptions.css";

const TimeOptions = ({
  open,
  onClickHour,
  minHour,
  maxHour,
  onClose,
}: {
  open: boolean;
  onClickHour: (hour: number) => void;
  minHour?: number;
  maxHour?: number;
  onClose: () => void; // 클릭 아웃사이드 이벤트에 반응할 함수
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  // 외부 클릭시 닫힘
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const parent = wrapperRef.current?.parentElement;
      if (open && parent && !parent.contains(e.target as Node)) {
        onClose();
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [onClose, open]);

  if (!open) return null;

  return (
    <TimePickerOuterWrapper
      ref={wrapperRef}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <TimePicker>
        <TimePickerInner>
          {Array.from(Array(25).keys()).map((hour) => (
            <TimePickerItem
              key={hour}
              disabled={
                Boolean(minHour && hour < minHour) ||
                Boolean(maxHour && hour > maxHour)
              }
              onClick={(e) => {
                if ((minHour && hour < minHour) || (maxHour && hour > maxHour))
                  return;

                e.preventDefault();
                e.stopPropagation();

                onClickHour(hour);
                onClose();
              }}
            >
              {hour}
            </TimePickerItem>
          ))}
        </TimePickerInner>
      </TimePicker>
    </TimePickerOuterWrapper>
  );
};

export default TimeOptions;
