import React from "react";
import { TimeSlotButton, TimeSlotWrapper } from "./TimeOptionChips.css";

interface Props {
  onClickTimeChip: (startTime: number, endTime: number) => void;
}

export const TimeOptionChips = ({ onClickTimeChip }: Props) => {
  return (
    <TimeSlotWrapper>
      <TimeSlotButton
        onClick={() => {
          onClickTimeChip(9, 18);
        }}
      >
        오전 9시 ~ 오후 6시
      </TimeSlotButton>
      <TimeSlotButton
        onClick={() => {
          onClickTimeChip(12, 18);
        }}
      >
        오후 12시 ~ 오후 6시
      </TimeSlotButton>
      <TimeSlotButton
        onClick={() => {
          onClickTimeChip(12, 24);
        }}
      >
        오후 12시 ~ 자정
      </TimeSlotButton>
      <TimeSlotButton
        onClick={() => {
          onClickTimeChip(18, 24);
        }}
      >
        오후 6시 ~ 자정
      </TimeSlotButton>
    </TimeSlotWrapper>
  );
};
