/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 300px;
  align-items: center;
  margin-bottom: 10px;
`;

export const ArrowButton = styled.button`
  border: 1px dashed #ff6b6b;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.div`
  font-size: 16px;
  color: #333;
`;

export const CalendarContainer = styled.div`
  width: 100%;

  .react-datepicker {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-size: 16px;
  }

  .react-datepicker__header {
    background-color: #fff;
    border-bottom: none;
  }

  .react-datepicker__current-month {
    font-size: 16px;
  }

  .react-datepicker__month {
    margin: 0;
  }
  .react-datepicker__month-container {
    width: 100%;
    min-width: 250px;
  }

  /* 요일 wrapper */
  .react-datepicker__day-names {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  /* 요일 */
  .react-datepicker__day-name {
    flex: 1;
    color: #333;
    padding: 15px 0 10px;
    width: auto;
    font-weight: 700;
  }

  .react-datepicker__week {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /* 날짜 숫자 */
  .react-datepicker__day {
    flex: 1;
    color: #303030;

    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;

    &.react-datepicker__day--outside-month {
      color: #303030;
    }
  }

  // 모바일일때는 없는게 맞음
  .react-datepicker__day--in-selecting-range {
    background-color: rgba(0, 0, 0, 0);
    /* background-color: #d7f6d7; */
  }

  .react-datepicker__day--keyboard-selected {
    background-color: rgba(0, 0, 0, 0);
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-range {
    background-color: #8dd88d;
    flex: 1;
  }

  .react-datepicker__day--disabled {
    color: #ccc !important;
  }

  /* picker 다음달/전 달 화살표 버튼*/
  .react-datepicker__navigation-icon::before {
    border-color: #4ccb84;
  }
`;
