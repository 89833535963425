import { useState } from "react";
import { Tab, TabButton, TransparentText, TabText } from "./index.css";
import TimeOptions from "./TimeOptions";

type ActiveTimeType = "start" | "end";

type Props = {
  setStartTime: (hour: number) => void;
  setEndTime: (hour: number) => void;
  startTime: number;
  endTime: number;
};

const TimePickerComponent = ({
  setEndTime,
  setStartTime,
  startTime,
  endTime,
}: Props) => {
  const [activeTab, setActiveTab] = useState<ActiveTimeType>("start");
  const [openTimeSelector, setOpenTimeSelector] = useState<boolean>(false);

  const handleTabClick = (tab: ActiveTimeType) => {
    setActiveTab(tab);
  };

  const handleHour = (type: ActiveTimeType) => (hour: number) => {
    if (type === "start") {
      setStartTime(hour);
    } else {
      setEndTime(hour);
    }
  };

  const renderTimeText = (hour: number) => {
    if (hour === 0 || hour === 24) return "오전 12시";
    if (hour < 12) return `오전 ${hour}시`;
    if (hour === 12) return "오후 12시";
    return `오후 ${hour - 12}시`;
  };

  return (
    <Tab>
      <TabButton
        text={renderTimeText(startTime)}
        active={activeTab === "start"}
        onClick={() => {
          handleTabClick("start");
          setOpenTimeSelector(true);
        }}
      >
        <TransparentText>{renderTimeText(startTime)}</TransparentText>
        <TimeOptions
          open={activeTab === "start" && openTimeSelector}
          onClickHour={handleHour("start")}
          maxHour={endTime !== 0 ? endTime - 1 : 23}
          onClose={() => {
            setOpenTimeSelector(false);
          }}
        />
      </TabButton>
      <TabText style={{ marginRight: "20px" }}>부터</TabText>

      <TabButton
        text={renderTimeText(endTime)}
        active={activeTab === "end"}
        onClick={() => {
          handleTabClick("end");
          setOpenTimeSelector(true);
        }}
      >
        <TransparentText>{renderTimeText(endTime)}</TransparentText>
        <TimeOptions
          open={activeTab === "end" && openTimeSelector}
          onClickHour={handleHour("end")}
          minHour={startTime + 1}
          onClose={() => {
            setOpenTimeSelector(false);
          }}
        />
      </TabButton>
      <TabText>까지</TabText>
    </Tab>
  );
};

export default TimePickerComponent;
