/** @jsxImportSource @emotion/react */

import styled from "@emotion/styled";

const Tab = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  color: #3e3e3e;
`;

const TabButton = styled.button<{ active: boolean; text: string }>`
  background: none;
  border: none;
  padding: 0 0 7px;
  margin: 0 10px;
  cursor: pointer;
  font-size: 1rem;

  border-bottom: ${(props) =>
    props.active ? "3px solid #6DDA9D" : "3px solid #949494"};
  box-sizing: border-box;

  font-weight: bold;
  color: #000;
  position: relative;

  &::after {
    content: ${(props) => `"${props.text}"`};
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    font-weight: ${(props) => (props.active ? "bold" : "normal")};

    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => (props.active ? "#000" : "#949494")};
  }
`;

const TransparentText = styled.span`
  opacity: 0;
`;

const TabText = styled.span`
  width: fit-content;
  white-space: nowrap;
`;

export { Tab, TabButton, TransparentText, TabText };
