import React, { useState } from "react";
import {
  Button,
  ButtonWrapper,
  Container,
  Label,
  ProgressDots,
  SubLabel,
} from "./MeetDateSelect.css";
import { AppScreen } from "../../../stackflow/components";
import { useFlow } from "../../../stackflow";
import DateRangePicker from "../components/DateRangePicker";
import DateRangeOptions from "../components/DateRangeOptions";
import { Spacing } from "@components/common/Spacing";
import format from "date-fns/format";
import { useQueryParams } from "../../../stackflow/hooks/useQueryParams";

const MeetDateSelect: React.FC = () => {
  const { push } = useFlow();
  const { title } = useQueryParams();
  const [startDate, setStartDate] = useState<Date | undefined>(new Date());
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);

  const handleMoveToTimeSelect = () => {
    push("NewMeetTimeSelect", {
      title,
      startDate: startDate && format(startDate, "yyyy-MM-dd"),
      endDate: endDate && format(endDate, "yyyy-MM-dd"),
    });
  };

  const enableButton = startDate !== undefined && endDate !== undefined;
  return (
    <AppScreen
      appBar={{
        border: false,
      }}
      accessoryBar={
        <ButtonWrapper>
          <Button enable={enableButton} onClick={handleMoveToTimeSelect}>
            다음
          </Button>
        </ButtonWrapper>
      }
    >
      <Container>
        <ProgressDots>
          <span className="active"></span>
          <span className="active"></span>
          <span></span>
        </ProgressDots>
        <Label>기간 선택</Label>
        <SubLabel>최대 14일까지 선택할 수 있어요.</SubLabel>
        <Spacing height={13} />
        <DateRangePicker
          startDateProps={[startDate, setStartDate]}
          endDateProps={[endDate, setEndDate]}
        />
        <Spacing height={30} />
        <DateRangeOptions
          startDateProps={[startDate, setStartDate]}
          endDateProps={[endDate, setEndDate]}
        />
      </Container>
    </AppScreen>
  );
};

export default MeetDateSelect;
