import { AvailableDateType, MemberType } from "@/apis/hooks/useGetMeetDetail";

export const DefaultTimeGridData = (
  nickname: string | undefined | null,
  memberData: MemberType[]
) => {
  if (!nickname) return {};

  const member = memberData.find((item) => {
    if (item.name === nickname) {
      return item;
    }
  });

  if (!member) return {};

  return transformMemberDataToSelectedTimeGridData(member.availableTime);
};

export const transformMemberDataToSelectedTimeGridData = (
  data: AvailableDateType[]
): Record<string, boolean> => {
  return data.reduce((acc, item) => {
    const key = `${item.date}-${item.hour}`;
    acc[key] = true;
    return acc;
  }, {} as Record<string, boolean>);
};

export const transformSelectedDateToKoreanResultText = (
  selectedDate: Record<string, boolean>
): string => {
  const groupedData = groupData(selectedDate);
  const result: string[] = [];

  Object.keys(groupedData).forEach((date) => {
    const formattedRanges = formatRanges(groupedData[date]);
    result.push(`${date.replace(/\//g, "-")}, ${formattedRanges}`);
  });

  return result.join("\n");
};

// 데이터를 날짜별로 그룹화하고, 연속된 시간을 묶습니다.
const groupData = (data: Record<string, boolean>) => {
  const grouped: { [date: string]: number[] } = {};

  Object.keys(data).forEach((key) => {
    const [date, hour] = key.split("-");
    if (!grouped[date]) {
      grouped[date] = [];
    }
    grouped[date].push(parseInt(hour, 10));
  });

  return grouped;
};

const formatRanges = (hours: number[]) => {
  hours.sort((a, b) => a - b);

  const ranges: string[] = [];
  let start = hours[0];
  let end = hours[0];

  for (let i = 1; i < hours.length; i++) {
    if (hours[i] === end + 1) {
      end = hours[i];
    } else {
      ranges.push(`${start}시~${end}시`);
      start = hours[i];
      end = hours[i];
    }
  }
  ranges.push(`${start}시~${end}시`);

  return ranges.join(", ");
};
