import { AppScreen } from "@/stackflow/components";
import { usePathParams } from "@/stackflow/hooks/usePathParams";
import {
  AvailableInfoText,
  ContentsWrapper,
  DateItem,
  DateText,
  DateWrapper,
  DayText,
  Divider,
  HourBox,
  HourColWrapper,
  HourDetailWrapper,
  HourDivider,
  HourDividerText,
  HourItem,
  HourText,
  PreviewResult,
  PreviewResultText,
  SelectedDateAndTimeText,
  Title,
  TopDataWrapper,
  UnAvailableInfoText,
  UserList,
  Wrapper,
} from "./index.css";
import {
  DayInfoType,
  generateDayInfoKey,
  generateDays,
} from "@components/common/TimeTableGrid";
import { Spacing } from "@components/common/Spacing";
import { useMemo, useState } from "react";
import { getCountLevel, groupDataByDate } from "./utils";
import useGetMeetDetail, {
  MeetDetailResponse,
  MemberType,
} from "@/apis/hooks/useGetMeetDetail";
import AsyncBoundary from "@components/common/AsyncBoundary/AsyncBoundary";
import React from "react";
import Spinner from "@components/common/Spinner";

import { useFlow } from "@/stackflow";
import FullSpinnerView from "@components/common/Spinner/FullSpinnerView";

const ResultPage = () => {
  const { meetId } = usePathParams();

  const { meet } = useGetMeetDetail(meetId);

  const loaded = meet?.success === true && meet?.data;

  return (
    <AsyncBoundary
      pendingFallback={
        <AppScreen>
          <FullSpinnerView />
        </AppScreen>
      }
    >
      {loaded ? (
        <ResultPageInner meet={meet.data.meet} />
      ) : (
        <div>
          <Spinner />
        </div>
      )}
    </AsyncBoundary>
  );
};

const ResultPageInner = ({ meet }: { meet: MeetDetailResponse["meet"] }) => {
  const days = generateDays(
    meet.openDate.startDate,
    meet.openDate.endDate,
    meet.openDate.startTime,
    meet.openDate.endTime
  );

  const handleOpenFeedbackForm = () => {
    window.open(
      `https://docs.google.com/forms/d/e/1FAIpQLSeNxf90NZMf45b44HnbRLhoxyYOIVlJkSPQwUI98nVvTgHIqw/viewform`,
      "_blank"
    );
  };
  const dayKeys = Object.keys(days);

  const [selectedDates, setSelectedDates] = useState<DayInfoType>(
    days[dayKeys[0]]
  );
  const [selectedHourSlot, setSelectedHourSlot] = useState<number>(
    meet.openDate.startTime
  );

  const hours = useMemo(
    () =>
      Array.from(
        {
          length: meet.openDate.endTime - meet.openDate.startTime + 1,
        },
        (_, i) => meet.openDate.startTime + i
      ),
    [meet.openDate.endTime, meet.openDate.startTime]
  );

  const groupedData = groupDataByDate(meet.members);
  const mappedHour = hours.map((hour) => {
    if (!selectedDates) {
      return {
        hour,
        count: 0,
      };
    }

    const count =
      groupedData[generateDayInfoKey(selectedDates)]?.times[hour]?.count || 0;

    return {
      hour,
      count,
    };
  });

  const allMemberList = meet.members.map((member: MemberType) => ({
    name: member.name,
    _id: member._id,
  }));

  // 클릭된 날짜의 시간에 따른 세부 정보를 가져오는 함수
  const selectedDateTimeData = useMemo(() => {
    if (!selectedDates || !selectedHourSlot) return undefined;

    const key = generateDayInfoKey(selectedDates);

    const selectedDateTimeData = groupedData[key]?.times[selectedHourSlot];
    if (!selectedDateTimeData)
      return {
        count: 0,
        members: [],
        unavailable: allMemberList,
      };
    return selectedDateTimeData;
  }, [allMemberList, groupedData, selectedDates, selectedHourSlot]);

  return (
    <AppScreen
      appBar={{
        title: "결과",
      }}
    >
      <Wrapper>
        <TopDataWrapper>
          <Title>{meet.title}</Title>
          <Spacing height={30} />
          <DateWrapper>
            {dayKeys.map((day) => {
              const dateData = days[day];

              // 해당 날짜에 대한 같은 시간 중 가장 많은 인원 수를 가져옴
              const countData = groupedData[generateDayInfoKey(dateData)];
              const countLevel = getCountLevel(
                countData?.maxCount ?? 0,
                allMemberList.length
              );

              return (
                <DateItem
                  countLevel={countLevel}
                  key={generateDayInfoKey(dateData)}
                  onClick={() => {
                    setSelectedDates(dateData);
                  }}
                  selected={
                    JSON.stringify(selectedDates) === JSON.stringify(dateData)
                  }
                >
                  <DayText>{dateData.day}</DayText>
                  <DateText>{dateData.date}</DateText>
                </DateItem>
              );
            })}
          </DateWrapper>
          <Spacing height={30} />
          <Divider />
        </TopDataWrapper>

        {selectedDates ? (
          <ContentsWrapper>
            <HourColWrapper>
              {mappedHour?.map((slot) => (
                <React.Fragment key={slot.hour}>
                  <HourItem
                    key={slot.hour}
                    onClick={() => {
                      setSelectedHourSlot(slot.hour);
                    }}
                  >
                    <HourText>{slot.hour}시</HourText>
                    <HourBox
                      selected={selectedHourSlot === slot.hour}
                      countLevel={getCountLevel(
                        slot.count,
                        allMemberList.length
                      )}
                    />
                  </HourItem>
                  {slot.hour === 12 && (
                    <HourItem key={"noon"}>
                      <HourDividerText>오후</HourDividerText>
                      <HourDivider />
                    </HourItem>
                  )}
                </React.Fragment>
              ))}
            </HourColWrapper>

            <HourDetailWrapper>
              <SelectedDateAndTimeText>
                {`${selectedDates.year}년 ${selectedDates.month}월 ${selectedDates.date}일 ${selectedHourSlot}시`}
              </SelectedDateAndTimeText>
              <Spacing height={20} />
              {selectedDateTimeData && (
                <>
                  <AvailableInfoText>가능</AvailableInfoText>
                  <Spacing height={10} />
                  <UserList>
                    {selectedDateTimeData.members.map((el, idx) => (
                      <div key={el._id + idx}>{el.name}</div>
                    ))}
                  </UserList>
                  <Spacing height={30} />
                  <UnAvailableInfoText>불가</UnAvailableInfoText>
                  <Spacing height={10} />
                  <UserList>
                    {selectedDateTimeData.unavailable.map((el, idx) => (
                      <div key={el._id + idx}>{el.name}</div>
                    ))}
                  </UserList>
                </>
              )}
            </HourDetailWrapper>
          </ContentsWrapper>
        ) : null}
        <PreviewResult>
          <PreviewResultText onClick={handleOpenFeedbackForm}>
            개발자에게 피드백 보내기
          </PreviewResultText>
        </PreviewResult>
      </Wrapper>
    </AppScreen>
  );
};

export default ResultPage;
