/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

const Card = styled.div`
  width: 300px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: left;
`;

const ProgressDots = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;

  & > span {
    width: 8px;
    height: 8px;
    background-color: #bbb;
    border-radius: 50%;
    margin-right: 5px;
  }

  & > span.active {
    background-color: #4ccb84;
  }
`;

const Label = styled.label`
  font-size: 16px;
  font-weight: bold;
  color: #333;
  display: block;
  margin-bottom: 10px;

  &::after {
    content: " *";
    color: red;
  }
`;

const SubLabel = styled.label`
  font-size: 16px;
  font-weight: bold;
  color: #333;
  display: block;
  margin-bottom: 10px;

  &::after {
    content: " *";
    color: red;
  }
`;

const Input = styled.input`
  width: 100%;
  width: -webkit-fill-available;
  padding: 10px 0;
  font-size: 14px;
  border: none;
  border-bottom: 3px solid #4ccb84;
  margin-bottom: 6px;
  outline: none;
`;

const CharCount = styled.div`
  width: 100%;
  text-align: right;
  font-size: 12px;
  color: #888;
  margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  padding: 0 18px 10px 18px;
  box-sizing: border-box;
`;

const Button = styled.button<{ enable: boolean }>`
  width: 100%;
  background-color: ${(props) => (props.enable ? "#4CCB84" : "#bbb")};
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 15px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  cursor: pointer;
`;

const ErrorText = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 10px;
  width: 100%;
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export {
  Container,
  Card,
  ProgressDots,
  Label,
  Input,
  CharCount,
  Button,
  ButtonWrapper,
  SubLabel,
  ErrorText,
};
