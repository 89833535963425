import {
  useMutation,
  UseMutationOptions,
  useQuery,
} from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";
import { MEETTIME_API_PATH } from "../constants";
import { fetchInstance } from "../instance";
import { MeetDetailResponse } from "./useGetMeetDetail";

export type NewMeetRequest = {
  title: string;
  startDate: string;
  endDate: string;
  startTime: number;
  endTime: number;
};

export type ResponseShell = {
  success: boolean;
  data: MeetDetailResponse;
};

export const postNewMeetPath = () => `${MEETTIME_API_PATH}/new`;

export const postNewMeet = async () => {
  const { data } = await fetchInstance().get<
    undefined,
    AxiosResponse<MeetDetailResponse>
  >(postNewMeetPath());

  return data;
};

type Props = UseMutationOptions<ResponseShell, Error, NewMeetRequest>;

export const usePostNewMeet = ({ onError, onSuccess }: Props) => {
  return useMutation({
    mutationFn: async (body: NewMeetRequest) => {
      const result = await axios.post(postNewMeetPath(), body);
      return result.data;
    },
    onError,
    onSuccess,
  });
};

export default usePostNewMeet;
