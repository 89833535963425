/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 16px 8px;
`;

export const Chip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 20px;
  background-color: #f1f1f1;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
`;

export const ArrowButton = styled.button`
  border: 1px dashed #ff6b6b;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.div`
  font-size: 16px;
  color: #333;
`;
