import React, { useState, useEffect, useCallback } from "react";
import {
  AllColWrapper,
  ColWrapper,
  Container,
  DateKoTextLabel,
  DateLabel,
  DayLabel,
  EmptyDayLabel,
  FirstTimeLabel,
  HourColWrapper,
  NewMonthDayLabel,
  NewMonthDivider,
  NewMonthText,
  NewMonthWrapper,
  NoonDivider,
  NoonText,
  NoonWrapper,
  TimeSlot,
  TimeTopTextLabel,
} from "./index.css";
import { format, addDays, differenceInDays, parse } from "date-fns";
import { ko } from "date-fns/locale";
import { detectMobileDevice } from "@utils/device";

interface Props {
  settedOriginStartDate: string;
  settedOriginEndDate: string;
  settedOriginStartTime: number;
  settedOriginEndTime: number;
  selectedSlots: Record<string, boolean>;
  setSelectedSlots: (slots: Record<string, boolean>) => void;
}

export type DayInfoType = {
  year: string;
  month: string;
  date: string;
  day: string;
  hours: number[];
};

const TimeTableGrid = ({
  settedOriginStartDate,
  settedOriginEndDate,
  settedOriginStartTime,
  settedOriginEndTime,
  selectedSlots,
  setSelectedSlots,
}: Props) => {
  const days = generateDays(
    settedOriginStartDate,
    settedOriginEndDate,
    settedOriginStartTime,
    settedOriginEndTime
  );

  const dayKeys = Object.keys(days);

  const hours = Array.from(
    { length: settedOriginEndTime - settedOriginStartTime + 1 },
    (_, i) => settedOriginStartTime + i
  );

  const [mode, setMode] = useState<"select" | "deselect">("select");
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [draggedSlots, setDraggedSlots] = useState<Record<string, boolean>>({});
  const [startSlot, setStartSlot] = useState<{
    dayInfo: DayInfoType;
    hour: number;
  } | null>(null);

  const handleMouseDown = useCallback(
    (dayInfo: DayInfoType, hour: number) => {
      // 첫 클릭 슬롯의 상태에 따라 mode를 결정
      const slotKey = `${generateDayInfoKey(dayInfo)}-${hour}`;
      const mode = selectedSlots[slotKey] ? "deselect" : "select";
      setMode(mode);

      setIsDragging(true);
      setStartSlot({ dayInfo, hour });
      setDraggedSlots({ [slotKey]: mode === "select" ? true : false });
    },
    [selectedSlots]
  );
  const handleMouseEnter = useCallback(
    (dayInfo: DayInfoType, hour: number) => {
      if (isDragging && startSlot) {
        const newDraggedSlots = { ...selectedSlots };

        // Parse dates to Date objects for accurate comparison
        const startDateObj = parse(
          `${startSlot.dayInfo.year}-${startSlot.dayInfo.month}-${startSlot.dayInfo.date}`,
          "yyyy-MM-dd",
          new Date()
        );
        const currentDateObj = parse(
          `${dayInfo.year}-${dayInfo.month}-${dayInfo.date}`,
          "yyyy-MM-dd",
          new Date()
        );

        // Determine the range of dates to iterate over
        const isStartBefore = startDateObj < currentDateObj;
        const [start, end] = isStartBefore
          ? [startDateObj, currentDateObj]
          : [currentDateObj, startDateObj];

        // Define the hour range
        const startHour = startSlot.hour > hour ? hour : startSlot.hour;
        const endHour = startSlot.hour > hour ? startSlot.hour : hour;

        // Iterate over each date within the range
        for (let date = start; date <= end; date = addDays(date, 1)) {
          const dateStr = format(date, "yyyy/MM/dd", { locale: ko });

          for (let h = startHour; h <= endHour; h++) {
            const slotKey = `${dateStr}-${h}`;
            newDraggedSlots[slotKey] = mode === "select";
          }
        }

        // Update dragged slots state
        setDraggedSlots(newDraggedSlots);
      }
    },
    [isDragging, mode, selectedSlots, startSlot]
  );

  const handleMouseUp = useCallback(() => {
    if (isDragging) {
      const updatedSlots = { ...selectedSlots, ...draggedSlots };
      console.log(updatedSlots);
      setSelectedSlots(updatedSlots);
      setDraggedSlots({});
      setIsDragging(false);
      setStartSlot(null);
    }
  }, [draggedSlots, isDragging, selectedSlots]);

  const handleTouchMove = useCallback(
    (e: React.TouchEvent<HTMLDivElement>) => {
      if (!isDragging || !startSlot) return;
      const touch = e.touches[0];

      const element = document.elementFromPoint(touch.clientX, touch.clientY);
      if (element && element.id && element.id.split("-").length === 2) {
        const [dayInfo, hour] = element.id.split("-");
        const [year, month, date] = (dayInfo ?? "").split("/");
        handleMouseEnter(
          {
            year,
            month,
            date,
            day: "",
            hours: [],
          },
          Number(hour)
        );
      }
    },
    [handleMouseEnter, isDragging, startSlot]
  );

  const isMobile = detectMobileDevice();

  useEffect(() => {
    const handleMouseUpOutside = () => {
      if (isDragging) {
        handleMouseUp();
      }
    };

    window.addEventListener("mouseup", handleMouseUpOutside);
    window.addEventListener("touchend", handleMouseUpOutside);

    return () => {
      window.removeEventListener("mouseup", handleMouseUpOutside);
      window.removeEventListener("touchend", handleMouseUpOutside);
    };
  }, [isDragging, draggedSlots, selectedSlots, handleMouseUp]);

  return (
    <Container
      dayNum={dayKeys.length}
      enableOverflow={isDragging ? false : true}
    >
      <AllColWrapper>
        <HourColWrapper key={"time-hour"}>
          <EmptyDayLabel>
            <DateKoTextLabel style={{ opacity: 0 }}>
              {days[dayKeys[0]].day}
            </DateKoTextLabel>
            <DateLabel style={{ opacity: 0 }}>
              {days[dayKeys[0]].date}
            </DateLabel>
            <TimeTopTextLabel>시간</TimeTopTextLabel>
          </EmptyDayLabel>
          {hours.map((hour, index) => {
            return (
              <React.Fragment key={`hour-${hour}_${index}`}>
                <FirstTimeLabel>{hour}</FirstTimeLabel>
                {hour === 12 && (
                  <NoonWrapper>
                    <NoonText>오후</NoonText>
                  </NoonWrapper>
                )}
              </React.Fragment>
            );
          })}
        </HourColWrapper>
        {dayKeys.map((dayInfo, index) => {
          const dateInfo = days[dayInfo];

          const colKey = generateDayInfoKey(dateInfo);
          return (
            <React.Fragment key={`${colKey}_${index}`}>
              {/* 신규 월(month) divider */}
              {dateInfo.date === "01" && (
                <ColWrapper key={`${colKey}_${index}_month`}>
                  <NewMonthDayLabel>
                    <DateKoTextLabel style={{ opacity: 0 }}>
                      {days[dayKeys[0]].day}
                    </DateKoTextLabel>
                    <DateLabel style={{ opacity: 0 }}>
                      {days[dayKeys[0]].date}
                    </DateLabel>
                    <NewMonthText>{Number(dateInfo.month)}월</NewMonthText>
                  </NewMonthDayLabel>
                  {dateInfo.hours.map((hour) => {
                    const slotKey = `${generateDayInfoKey(dateInfo)}-${hour}`;
                    return (
                      <React.Fragment key={slotKey + "month"}>
                        <NewMonthDivider />
                        {hour === 12 && (
                          <NoonWrapper>
                            <NoonDivider />
                          </NoonWrapper>
                        )}
                      </React.Fragment>
                    );
                  })}
                </ColWrapper>
              )}
              <ColWrapper key={`${colKey}_${index}`}>
                <DayLabel>
                  <DateKoTextLabel>{dateInfo.day}</DateKoTextLabel>
                  <DateLabel>{dateInfo.date}</DateLabel>
                </DayLabel>

                {dateInfo.hours.map((hour) => {
                  const slotKey = `${generateDayInfoKey(dateInfo)}-${hour}`;
                  const isSelected = !!selectedSlots[slotKey];
                  const isDraggingSelected = !!{
                    ...selectedSlots,
                    ...draggedSlots,
                  }[slotKey];

                  return (
                    <React.Fragment key={slotKey}>
                      <TimeSlot
                        key={slotKey}
                        id={slotKey}
                        hoverMode={!isMobile}
                        selected={isDragging ? isDraggingSelected : isSelected}
                        onMouseDown={() => {
                          if (isMobile) return;
                          handleMouseDown(dateInfo, hour);
                        }}
                        onMouseEnter={() => {
                          if (isMobile) return;
                          handleMouseEnter(dateInfo, hour);
                        }}
                        onMouseUp={() => {
                          if (isMobile) return;
                          handleMouseUp();
                        }}
                        onTouchStart={() => handleMouseDown(dateInfo, hour)}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleMouseUp}
                      />
                      {hour === 12 && (
                        <NoonWrapper>
                          <NoonDivider />
                        </NoonWrapper>
                      )}
                    </React.Fragment>
                  );
                })}
              </ColWrapper>
            </React.Fragment>
          );
        })}
      </AllColWrapper>
    </Container>
  );
};

export default TimeTableGrid;

export const generateDays = (
  startDate: string,
  endDate: string,
  startTime: number,
  endTime: number
): { [key: string]: DayInfoType } => {
  let days: { [key: string]: DayInfoType } = {};
  const start = new Date(startDate);
  const end = new Date(endDate);
  const numDays = differenceInDays(end, start) + 1;

  for (let i = 0; i < numDays; i++) {
    const date = addDays(start, i);

    days = {
      ...days,
      [format(date, "yyyy/MM/dd", { locale: ko })]: {
        year: format(date, "yyyy", { locale: ko }), // Year
        month: format(date, "MM", { locale: ko }), // Month
        date: format(date, "dd", { locale: ko }), // Day of the month
        day: format(date, "E", { locale: ko }), // Short day name in Korean
        hours: Array.from(
          { length: Number(endTime) - Number(startTime) + 1 },
          (_, i) => Number(startTime) + i
        ),
      },
    };
  }
  return days;
};

export const generateDayInfoKey = (dayInfo: DayInfoType) => {
  return `${dayInfo.year}/${dayInfo.month}/${dayInfo.date}`;
};
