import { MeetDetailResponse, MemberType } from "@/apis/hooks/useGetMeetDetail";

// TypeScript 인터페이스
export interface AvailableTime {
  date: string;
  hour: number;
}

export interface Member {
  memberId: number;
  name: string;
  availableTime: AvailableTime[];
}

// Mock 데이터 (예시 데이터)
export const MOCK_DATA: MeetDetailResponse["meet"] = {
  _id: "1",
  title: "다음주에 만날사람~",
  openDate: {
    startDate: "2024-08-01",
    endDate: "2024-08-12",
    startTime: 9,
    endTime: 24,
  },
  members: [
    {
      _id: "1",
      name: "김민수",
      availableTime: [
        {
          _id: "1",
          date: "2024/08/01",
          hour: 9,
        },
        {
          _id: "1",
          date: "2024/08/01",
          hour: 10,
        },
      ],
    },
    {
      _id: "2",
      name: "철수",
      availableTime: [
        { _id: "1", date: "2024/08/01", hour: 10 },
        { _id: "1", date: "2024/08/01", hour: 12 },
        { _id: "1", date: "2024/08/01", hour: 13 },
        { _id: "1", date: "2024/08/02", hour: 13 },
      ],
    },
  ],
};

export function groupDataByDate(members: MemberType[]) {
  // dateMap 구조: 날짜별로 시간대와 최대 가능 인원 수를 저장
  const dateMap: Record<
    string,
    {
      maxCount: number; // 가장 많은 인원이 가능한 시간대의 인원 수
      times: Record<
        number,
        {
          count: number;
          members: {
            name: string;
            _id: string;
          }[];
          unavailable: {
            name: string;
            _id: string;
          }[];
        }
      >;
    }
  > = {};

  const allMembers = members.map((member) => ({
    name: member.name,
    _id: member._id,
  }));

  // 모든 멤버에 대해 가능한 시간대를 순회
  members.forEach((member) => {
    member.availableTime.forEach((time) => {
      // 날짜별로 초기화
      if (!dateMap[time.date]) {
        dateMap[time.date] = { maxCount: 0, times: {} };
      }

      // 시간별로 초기화
      if (!dateMap[time.date].times[time.hour]) {
        dateMap[time.date].times[time.hour] = {
          count: 0,
          members: [],
          unavailable: allMembers,
        };
      }

      // 시간별 인원 추가
      dateMap[time.date].times[time.hour].count += 1;
      dateMap[time.date].times[time.hour].members.push({
        name: member.name,
        _id: member._id,
      });
      dateMap[time.date].times[time.hour].unavailable = dateMap[
        time.date
      ].times[time.hour].unavailable.filter((item) => item._id !== member._id);

      // 날짜별 최대 인원 수 업데이트
      dateMap[time.date].maxCount = Math.max(
        dateMap[time.date].maxCount,
        dateMap[time.date].times[time.hour].count
      );
    });
  });

  return dateMap;
}

export const getCountLevel = (count: number, wholeMemberCount: number) => {
  if (count === 0) return "empty";
  if (count === wholeMemberCount) return "high";
  if (count >= Math.floor(wholeMemberCount / 2)) return "medium";
  return "low";
};
