import { useFlow } from "@/stackflow";
import { AppScreen } from "@/stackflow/components";
import { usePathParams } from "@/stackflow/hooks/usePathParams";
import { Spacing } from "@components/common/Spacing";
import { NICKNAME_LOCALSTORAGE_KEY } from "@hooks/useLogin";
import React, { useState } from "react";
import {
  BottomSheetInner,
  BottomSheetOutter,
  Button,
  ButtonWrapper,
  CharacterCounter,
  FormContainer,
  InputContainer,
  Label,
  RequiredAsterisk,
  StyledInput,
} from "./index.css";

type Props = {
  handleOpenLoginBottomSheet: () => void;
  handleSetNickname: (nickname: string) => void;
};

const VoteSetInfoLoginBottomSheet = ({
  handleSetNickname,
  handleOpenLoginBottomSheet,
}: Props) => {
  const [nickname, setNickname] = useState<string>("");

  const enableButton = nickname.length > 0;

  const handleMoveToVote = () => {
    if (!enableButton) return;

    localStorage.setItem(NICKNAME_LOCALSTORAGE_KEY, nickname);
    handleSetNickname(nickname);
    handleOpenLoginBottomSheet();
  };

  return (
    <BottomSheetOutter>
      <BottomSheetInner>
        <FormContainer>
          <InputContainer>
            <Label>
              닉네임
              <RequiredAsterisk>*</RequiredAsterisk>
            </Label>
            <Spacing height={20} />
            <StyledInput
              type="text"
              placeholder={"입력하기"}
              maxLength={10}
              value={nickname}
              onChange={(e) => setNickname(e.target.value)}
            />
            <CharacterCounter>{nickname.length}/10</CharacterCounter>
          </InputContainer>
          <Spacing height={30} />
          <ButtonWrapper>
            <Button enable={enableButton} onClick={handleMoveToVote}>
              완료
            </Button>
          </ButtonWrapper>
        </FormContainer>
      </BottomSheetInner>
    </BottomSheetOutter>
  );
};

export default VoteSetInfoLoginBottomSheet;
