/** @jsxImportSource @emotion/react */

import styled from "@emotion/styled";

const TimeSlotWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10px 5px;
`;

const TimeSlotButton = styled.button`
  background-color: #f0f0f0;
  border: none;
  border-radius: 20px;
  padding: 5px 10px;
  margin: 5px;
  cursor: pointer;
  font-size: 1rem;
  color: #000;

  &:hover {
    background-color: #e0e0e0;
  }
`;

export { TimeSlotWrapper, TimeSlotButton };
