import {
  useMutation,
  UseMutationOptions,
  useQuery,
} from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";
import { MEETTIME_API_PATH } from "../constants";
import { fetchInstance } from "../instance";
import { AvailableDateType, MeetDetailResponse } from "./useGetMeetDetail";

export type MemberMeetInfoRequest = {
  meetId: string;
  name: string;
  availableTime: AvailableDateType[];
};

export type ResponseShell = {
  success: boolean;
  data: MeetDetailResponse;
};

export const putMemberMeetInfoPath = (meetId: string) =>
  `${MEETTIME_API_PATH}/${meetId}`;

export const putMemberMeetInfo = async (meetId: string) => {
  const { data } = await fetchInstance().get<
    undefined,
    AxiosResponse<MeetDetailResponse>
  >(putMemberMeetInfoPath(meetId));

  return data;
};

type Props = UseMutationOptions<ResponseShell, Error, MemberMeetInfoRequest>;

export const usePutMemberMeetInfo = ({ onError, onSuccess }: Props) => {
  return useMutation({
    mutationKey: ["putMemberMeetInfo"],
    mutationFn: async (body: MemberMeetInfoRequest) => {
      const result = await axios.put(putMemberMeetInfoPath(body.meetId), body);
      return result.data;
    },
    onError,
    onSuccess,
  });
};

export default usePutMemberMeetInfo;
