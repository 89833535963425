import styled from "@emotion/styled";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const TopDataWrapper = styled.div`
  /* flex: 1; */
  height: max-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
export const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  padding: 30px 20px 0 20px;
`;

export const DateWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 1rem;
  gap: 4px;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

// 날짜&요일 박스
export const DateItem = styled.div<{
  selected: boolean;
  countLevel: "empty" | "low" | "medium" | "high";
}>`
  min-width: 45px;
  max-width: 45px;
  height: auto;
  border-radius: 6px;
  background-color: ${(props) => {
    switch (props.countLevel) {
      case "empty":
        return "#D6FFE8";
      case "low":
        return "#A2EDC4";
      case "medium":
        return "#4CCB84";
      case "high":
        return "#27A75C";
    }
  }};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;

  gap: 9px;
  border: ${(props) =>
    props.selected ? "2px solid #FF3F3F" : "2px solid transparent"};
  box-sizing: border-box;
`;

export const DayText = styled.div`
  font-size: 14px;
  font-weight: 700;
`;

export const DateText = styled.div`
  font-size: 18px;
  font-weight: 700;
`;

export const Divider = styled.div`
  width: 100%;
  height: 5px;
  background-color: #f6f6f6;
`;

export const ContentsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px 20px;
  gap: 30px;
  overflow-y: auto;
  position: relative;
`;

export const HourColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2px;
`;

export const HourItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
`;

export const HourText = styled.div`
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
`;

export const HourDividerText = styled.div`
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  text-align: center;
  color: #949494;
  font-size: 12px;
  font-weight: 700;
  white-space: nowrap;
`;
export const HourDivider = styled.div`
  width: 100%;
  height: 1px;
  border-top: 1px dashed #949494;
`;

export const HourBox = styled.div<{
  selected: boolean;
  countLevel: "empty" | "low" | "medium" | "high";
}>`
  width: 50px;
  height: 40px;
  border-radius: 6px;
  border: ${(props) =>
    props.selected ? "2px solid #FF3F3F" : "2px solid transparent"};
  box-sizing: border-box;

  background-color: ${(props) => {
    switch (props.countLevel) {
      case "empty":
        return "#D6FFE8";
      case "low":
        return "#A2EDC4";
      case "medium":
        return "#4CCB84";
      case "high":
        return "#27A75C";
    }
  }};
`;

// 시간 선택시 나오는 상세 정보 박스
export const HourDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
`;
export const SelectedDateAndTimeText = styled.div`
  font-size: 14px;
  font-weight: 700;
`;

//가능 / 불가능 텍스트
export const AvailableInfoText = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #0fa953;
`;
export const UnAvailableInfoText = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #fa5757;
`;

export const UserList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const PreviewResult = styled.div`
  color: #bebebe;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;

  width: 100%;
  display: flex;
  justify-content: center;

  padding: 10px 0;
`;

export const PreviewResultText = styled.div`
  width: "auto";
`;
