import React from "react";
import {
  Button,
  ButtonWrapper,
  Card,
  CharCount,
  Container,
  Input,
  Label,
  ProgressDots,
} from "./MeetName.css";
import { AppScreen } from "../../../stackflow/components";
import { useFlow } from "../../../stackflow";

const MeetName: React.FC = () => {
  const { push } = useFlow();
  const [title, setTitle] = React.useState<string>("");

  const handleMoveToDateSelect = () => {
    if (title.length === 0) return;
    push("NewMeetDateSelect", { title });
  };
  const handleOnChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    // 공백으로 시작하는 경우 모든공백을 제거 정규식
    const spaceRegex = /^\s+/g;
    if (spaceRegex.test(e.target.value)) {
      setTitle(e.target.value.replace(spaceRegex, ""));
      return;
    }

    setTitle(e.target.value);
  };

  const enableButton = title.length > 0;
  return (
    <AppScreen
      appBar={{
        border: false,
      }}
      accessoryBar={
        <ButtonWrapper>
          <Button enable={enableButton} onClick={handleMoveToDateSelect}>
            다음
          </Button>
        </ButtonWrapper>
      }
    >
      <Container>
        <ProgressDots>
          <span className="active"></span>
          <span></span>
          <span></span>
        </ProgressDots>
        <Label>제목</Label>
        <Input
          type="text"
          placeholder="입력하기"
          maxLength={15}
          onChange={handleOnChangeInput}
          value={title}
        />
        <CharCount>{title.length}/15</CharCount>
      </Container>
    </AppScreen>
  );
};

export default MeetName;
