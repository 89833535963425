import styled from "@emotion/styled";

export const BottomSheetOutter = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const BottomSheetInner = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  padding: 0 0 10px 0;
  box-sizing: border-box;
`;

export const Button = styled.button<{ enable: boolean }>`
  width: 100%;
  background-color: ${(props) => (props.enable ? "#4CCB84" : "#bbb")};
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 15px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  cursor: pointer;
`;
export const FormContainer = styled.div`
  width: 100%;
  padding: 1.25rem;
`;

export const InputContainer = styled.div``;

export const Label = styled.label`
  display: block;
  font-weight: bold;

  font-size: 20px;
  line-height: 20px;
`;

export const RequiredAsterisk = styled.span`
  color: red;
  margin-left: 2px;
`;

export const HelperText = styled.p`
  font-size: 0.875rem;
  color: #888;
`;

export const StyledInput = styled.input`
  width: 100%;
  padding: 8px 0;
  border: none;
  border-bottom: 2px solid #a9dbb8;
  font-size: 1rem;
  outline: none;
  transition: border-color 0.3s ease;

  &:focus {
    border-bottom-color: #66bb6a;
  }

  &::placeholder {
    color: #ccc;
  }
`;

export const CharacterCounter = styled.div`
  font-size: 0.875rem;
  text-align: right;
  margin-top: 5px;
  color: #888;
`;
